import(/* webpackMode: "eager", webpackExports: ["Faq"] */ "/vercel/path0/components/Faq.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/img/benefit-1.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/img/benefit-2.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/img/user1.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/img/user2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/img/user3.png");
