"use client";
import { Container } from "@component/Container";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";

export const Faq = () => {
  return (
    <Container className="!p-0">
      <div className="w-full max-w-2xl p-2 mx-auto rounded-2xl">
        {faqdata.map((item, index) => (
          <div key={index} className="mb-5">
            <Disclosure>
              {({ open }) => (
                <>
                  <DisclosureButton className="flex items-center justify-between w-full px-4 py-4 text-lg text-left text-gray-800 rounded-lg bg-gray-50 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-indigo-100 focus-visible:ring-opacity-75 dark:bg-trueGray-800 dark:text-gray-200">
                    <span>{item.question}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "transform rotate-180" : ""
                      } w-5 h-5 text-indigo-500`}
                    />
                  </DisclosureButton>
                  <DisclosurePanel className="px-4 pt-4 pb-2 text-gray-500 dark:text-gray-300">
                    {item.answer}
                  </DisclosurePanel>
                </>
              )}
            </Disclosure>
          </div>
        ))}
      </div>
    </Container>
  );
};

const faqdata = [
  {
    question: (
      <ul>
        <li className="text-black dark:text-biruMuda-400 list-none font-bold">
          Saya ingin memesan layanan pemasangan, kapan waktu pemasangan nya akan
          di kerjakan?
        </li>
      </ul>
    ),
    answer: (
      <ul>
        <li className="text-black list-none dark:text-white">
          Jadwal Pemasangan akan di tentukan oleh persetujuan dari kedua belah
          pihak antara kami dan para pelanggan
        </li>
      </ul>
    ),
  },
  {
    question: (
      <ul>
        <li className="text-black dark:text-biruMuda-400 list-none font-bold">
          Bagaimana cara dan proses pemasangan saat memesan di Sahabad Solution?
        </li>
      </ul>
    ),
    answer: (
      <ul>
        <li className="text-black list-none dark:text-white">
          Bisa Menghubungi kami via Whattsapp yang ada di tombol chat kanan
          bawah
        </li>
      </ul>
    ),
  },
  {
    question: (
      <ul>
        <li className="text-black dark:text-biruMuda-400 list-none font-bold">
          Layanan Apa saja yang tersedia?
        </li>
      </ul>
    ),
    answer: (
      <ul>
        <li className="text-black list-none dark:text-white">
          1. Kami Melayani Jasa Pemasangan, Pembongkaran dan Repair Furniture
          dengan Nama Sahabad Solution Furniture.
        </li>
        <li className="text-black list-none dark:text-white">
          2. Kami juga melayani jasa pembersihan rumah agar rumah bersih dan
          tampak rapih bernama Sahabad Cleaning.
        </li>
        <li className="text-black list-none dark:text-white">
          3. Ditambah, Kami juga melayani jasa perawatan hewan seperti
          Memandikan Kucing dan lain lain bernama Sahabat Pet Grooming
        </li>
      </ul>
    ),
  },
  {
    question: (
      <ul>
        <li className="text-black dark:text-biruMuda-400 list-none font-bold">
          Saya tertarik dan ingin berdiskusi lebih lanjut
        </li>
      </ul>
    ),
    answer: (
      <ul>
        <li className="text-black list-none dark:text-white">
          Kamu Bisa Klik Tombol Chat di kanan bawah dan memilih metode untuk
          Menghubungi kami
        </li>
      </ul>
    ),
  },
];
